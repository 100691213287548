import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import {
  a125,
  a128,
  a131,
  a134,
  a137,
  a149,
  a152,
  a153,
  a163,
  a168,
  a169,
  a18,
  a20,
  a22,
  a28,
  a30,
  a33,
  a35,
  a68,
  a7,
  a71,
  a72,
  a89,
  s1,
} from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";
import { FaImages } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import AlertMessage from "../../../Other Functions/AlertMessage";

export default function AdminAddSku() {
  const [active, setActive] = useState("List");
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageToShow, setMessageToShow] = useState("");
  const [totalMetalAmount, setTotalMetalAmount] = useState("");
  const [totalLabourAmount, setLabourAmount] = useState("");
  const [totalStoneAmount, setTotalStoneAmount] = useState("");
  const [totalProductAmount, setTotalProductAmount] = useState("");
  const [filesType, setFilesType] = useState(true);
  const [selectedSkuId, setSelectedSkuId] = useState(0);
  const [partyTypeId, setPartyTypeId] = useState("");
  const [category, setCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [collection, setCollection] = useState("");
  const [purity, setPurity] = useState("");
  const [productTypeData, setProductTypeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [purityData, setPurityData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [collectionTypeData, setCollectionTypeData] = useState([]);

  const [stockKeepingUnit, setStockKeepingUnit] = useState("");
  const [productRemark, setProductRemark] = useState("");
  const [grosswt, setGrosswt] = useState(0);
  const [netWt, setNetWt] = useState(0);
  const [stoneWeight, setStoneWeight] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [size, setSize] = useState("");
  const [description, setDescription] = useState("");
  const [making_per_gram, setMaking_per_gram] = useState(0);
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState(0);
  const [making_Percentage, setMaking_Percentage] = useState(0);
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState(0);
  const [mrp, setMRP] = useState(0);
  const [color, setColor] = useState("");

  const [selectedStoneRate, setSelectedStoneRate] = useState(1);
  const [selectedDiamondRate, setSelectedDiamondRate] = useState(1);
  const [saleType, setSaleType] = useState("MRP");
  const [stonePieces, setStonePieces] = useState(0);
  const [allStones, setAllStones] = useState([]);
  const [oldEntry, setOldEntry] = useState(false);
  const [allStonesList, setAllStonesList] = useState([]);
  const [allDiamonds, setAllDiamonds] = useState([]);

  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  const clientCode = adminLoggedIn.ClientCode;
  const CompanyId = adminLoggedIn.CompanyId;
  const CounterId = adminLoggedIn.CounterId;
  const BranchId = adminLoggedIn.BranchId;
  const EmployeId = adminLoggedIn.EmployeId;
  const employeeCode = adminLoggedIn.EmployeeCode;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  const [addStone, setAddStone] = useState({
    StoneName: "",
    StoneWeight: 0,
    StonePieces: 0,
    StoneRate: 0,
    StoneAmount: 0,
    Description: "",
    ClientCode: clientCode,
    EmployeeCode: "",
    ProductId: 0,
  });
  const [addDiamond, setAddDiamond] = useState({
    DiamondName: "",
    DiamondWeight: 0,
    DiamondRate: 0,
    DiamondPieces: 0,
    DiamondClarity: "",
    DiamondColour: "",
    DiamondCut: "",
    DiamondShape: "",
    DiamondSize: 0,
    Certificate: "",
    SettingType: "",
    DiamondAmount: 0,
    DiamondPurchaseAmt: 0,
    Description: "",
    ClientCode: clientCode,
  });

  const [allSku, setAllSku] = useState([]);
  const [newSku, setNewSku] = useState({
    BlackBeads: "",
    BoxId: "0",
    BoxName: "",
    BranchId: 0,
    BranchName: "Home",
    CategoryId: 1,
    ClientCode: clientCode,
    CollectionName: "",
    Colour: "",
    CompanyId: 0,
    CounterId: 0,
    CuttingGrossWt: "",
    CuttingNetWt: "",
    Description: "",
    DesignId: 0,
    Diamonds: [],
    EmployeeId: 0,
    EstimatedDays: "0",
    Featured: "Yes",
    Gender: "",
    GrossWt: "0",
    HSNCode: "",
    HUIDCode: "",
    HallmarkAmount: "0",
    Height: "0",
    Id: 0,
    Images: "",
    MRP: "0",
    MakingFixedAmt: "0",
    MakingFixedWastage: "0",
    MakingPerGram: "0",
    MakingPercentage: "0",
    Margin: "0",
    MetalName: "",
    MetalRate: "0",
    MinQuantity: "0",
    MinWeight: "0",
    NetWt: "0",
    OccassionName: "",
    OfferPrice: "0",
    Pieces: "1",
    ProductId: 0,
    ProductRemark: "",
    PurchaseCost: "",
    PurityId: 0,
    Quantity: "1",
    Ranking: "0",
    Size: "0",
    SketchNo: "",
    Status: "Active",
    StockKeepingUnit: "",
    Stones: [],
    TotalDiamondAmount: "0",
    TotalDiamondPieces: "0",
    TotalDiamondWeight: "0",
    TotalStoneAmount: "0",
    TotalStonePieces: "0",
    TotalStoneWeight: "0",
    VendorId: 0,
    WeightCategories: "",
    Width: "0",
    oldEntry: false,
  });
  const formData = {
    ClientCode: clientCode,
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const fetchAllSku = async () => {
    try {
      const response = await fetch(a163, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log(data);
      setAllSku(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllSku();
  }, []);
  console.log(allSku);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});

  const handleSaveClick = () => {
    handleSubmit();
    // Save the edited data to your state or send it to an API
    console.log("Edited Data:", editedData);
    setEditingId(null); // Exit editing mode
  };
  const handleSubmit = async () => {
    try {
      const response = await fetch(a35, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedData),
      });
      const data = await response.json();
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
      } else {
        setMessageType("success");
        setMessageToShow("Updated successfully");
        setShowError(true);
      }
      console.log(data, "updated");
      fetchAllSku();
    } catch (error) {
      console.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setEditedData({ ...editedData, [name]: value });
  };

  const handleNewSkuChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    if (name == "StockKeepingUnit") {
      setNewSku({ ...newSku, [name]: value.toUpperCase() });
    } else if (name == "GrossWt") {
      if (value !== "") {
        setNewSku({
          ...newSku,
          [name]: value,
          NetWt: 0,
          TotalStoneWeight: 0,
        });
      } else {
        setNewSku({
          ...newSku,
          [name]: 0,
          NetWt: 0,
          TotalStoneWeight: 0,
        });
      }
      // newSku.NetWt=parseFloat(value) - parseFloat(newSku.TotalStoneWeight)
      // setNetWt(parseFloat(e.target.value) - parseFloat(stoneWeight));
    } else if (name == "TotalStoneWeight") {
      if (value !== "") {
        value >= 0 && value <= parseFloat(newSku.GrossWt)
          ? setNewSku({
              ...newSku,
              [name]: value,
              NetWt: parseFloat(
                parseFloat(newSku.GrossWt) - parseFloat(value)
              ).toFixed(3),
            })
          : null;
      } else {
        setNewSku({
          ...newSku,
          [name]: 0,
          NetWt: 0,
        });
      }
    } else if (
      name == "MakingPercentage" ||
      name == "MakingPerGram" ||
      name == "MakingFixedAmt" ||
      name == "MakingFixedWastage" ||
      name == "MRP"
    ) {
      if (value !== "") {
        setNewSku({
          ...newSku,
          [name]: value,
        });
      } else {
        setNewSku({
          ...newSku,
          [name]: 0,
        });
      }
    } else {
      setNewSku({ ...newSku, [name]: value });
    }
  };
  console.log(editedData, "editedData");
  const addNewSku = async (e) => {
    e.preventDefault();

    // const formData = new FormData();

    // Append regular fields
    // formData.append("StockKeepingUnit", stockKeepingUnit.toUpperCase());
    // formData.append("Description", description);
    // formData.append("ProductRemark", productRemark);
    // formData.append("ProductType", productTypeName);
    // formData.append("Purity", purityName);
    // formData.append("Colour", color);
    // formData.append("Collection", collectionName);
    // formData.append("CategoryId", parseInt(categoryId)); // Convert to string
    // formData.append("Category", categoryName.toString()); // Convert to string
    // formData.append("Size", size);
    // formData.append("GrossWt", grosswt.toString()); // Convert to string
    // formData.append("NetWt", netWt.toString()); // Convert to string
    // formData.append("TotalStoneWt", stoneWeight.toString()); // Convert to string

    // // Append StoneAmounts, StoneNames, StoneWts

    // // Append other fields
    // formData.append("MakingPercentage", making_Percentage.toString()); // Convert to string
    // formData.append("MakingFixedAmt", making_Fixed_Amt.toString()); // Convert to string
    // formData.append("MakingFixedWastage", making_Fixed_Wastage.toString()); // Convert to string
    // formData.append("MakingPerGram", making_per_gram.toString()); // Convert to string
    // formData.append("MRP", mrp.toString()); // Convert to string
    // formData.append("SaleType", saleType);
    // formData.append("StonePieces", stonePieces.toString()); // Convert to string
    // formData.append("PurityId", parseInt(purityId));
    // formData.append("ProductTypeId", parseInt(productTypeId));
    // formData.append("CollectionId", parseInt(collectionId)); // Convert to string
    // oldEntry ? formData.append("Id", parseInt(selectedSkuId)) : null;
    // // Images
    // filesType
    //   ? selectedFiles.forEach((file) => {
    //       formData.append("Images", file);
    //     })
    //   : formData.append("Images", selectedFiles);

    // // Now you can use this formData object in your fetch request
    // console.log(formData, "formData");
    // for (const entry of formData.entries()) {
    //   console.log(entry);
    // }
    // console.log(JSON.stringify(newSku), "formData");
    try {
      const response = await fetch(!oldEntry ? a168 : a169, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSku),
      });
      const data = await response.json();
      fetchAllSku();
      setActive("List");
      setNewSku({
        BlackBeads: "",
        BoxId: "0",
        BoxName: "",
        BranchId: 0,
        BranchName: "Home",
        CategoryId: 1,
        ClientCode: clientCode,
        CollectionName: "",
        Colour: "",
        CompanyId: 0,
        CounterId: 0,
        CuttingGrossWt: "",
        CuttingNetWt: "",
        Description: "",
        DesignId: 0,
        Diamonds: [],
        EmployeeId: 0,
        EstimatedDays: "0",
        Featured: "Yes",
        Gender: "",
        GrossWt: "0",
        HSNCode: "",
        HUIDCode: "",
        HallmarkAmount: "0",
        Height: "0",
        Id: 0,
        Images: "",
        MRP: "0",
        MakingFixedAmt: "0",
        MakingFixedWastage: "0",
        MakingPerGram: "0",
        MakingPercentage: "0",
        Margin: "0",
        MetalName: "",
        MetalRate: "0",
        MinQuantity: "0",
        MinWeight: "0",
        NetWt: "0",
        OccassionName: "",
        OfferPrice: "0",
        Pieces: "1",
        ProductId: 0,
        ProductRemark: "",
        PurchaseCost: "",
        PurityId: 0,
        Quantity: "1",
        Ranking: "0",
        Size: "0",
        SketchNo: "",
        Status: "Active",
        StockKeepingUnit: "",
        Stones: [],
        TotalDiamondAmount: "0",
        TotalDiamondPieces: "0",
        TotalDiamondWeight: "0",
        TotalStoneAmount: "0",
        TotalStonePieces: "0",
        TotalStoneWeight: "0",
        VendorId: 0,
        WeightCategories: "",
        Width: "0",
        oldEntry: false,
      });
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
        setActive("AddNew");
      } else {
        setMessageType("success");
        setMessageToShow("SKU Added Successfully");
        setShowError(true);
      }
      resetStateValues();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  const resetStateValues = () => {
    setPartyTypeId("");
    setCategory("");
    setProductType("");
    setCollection("");
    setPurity("");
    // setProductTypeData([]);
    // setCategoriesData([]);
    // setPurityData([]);
    // setPartyData([]);
    // setBoxData([]);
    // setCollectionTypeData([]);

    setStockKeepingUnit("");
    setProductRemark("");
    setGrosswt(0);
    setNetWt(0);
    setStoneWeight(0);
    setSelectedFiles([]);
    setSize("0");
    setDescription("");
    setMaking_per_gram(0);
    setMaking_Fixed_Amt(0);
    setMaking_Percentage(0);
    setMaking_Fixed_Wastage(0);
    setMRP(0);
    setColor("");

    setSaleType("MRP");
    setStonePieces(0);
    // setAllStones([]);
    setFilesType(true);
    setOldEntry(false);
    setSelectedSkuId(0);
  };
  //   Add bulk stock

  useEffect(() => {
    fetch(a125, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setCategoriesData(data));
  }, []);
  console.log(categoriesData, "categoriesData");
  useEffect(() => {
    fetch(a128, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((x) => x.json())
      .then((y) => setProductTypeData(y));
  }, []);
  // console.log(productTypeData.category_id);

  useEffect(() => {
    fetch(a134, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setPurityData(data));
    // console.log(purityData);
  }, []);
  useEffect(() => {
    fetch(a149, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setPartyData(data));
  }, []);
  useEffect(() => {
    fetch(a131, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setCollectionTypeData(data));
  }, []);
  // console.log(partyData);
  useEffect(() => {
    fetch(a137, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setBoxData(data));
  }, []);
  useEffect(() => {
    fetch(a152, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setAllStones(data));
  }, []);
  useEffect(() => {
    fetch(a153, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setAllDiamonds(data));
  }, []);

  let categoryId = parseInt(category.split(",")[0]);
  let categoryName = category.split(",")[1];
  let productTypeId = parseInt(productType.split(",")[0]);
  let productTypeName = productType.split(",")[1];
  let collectionId = parseInt(collection.split(",")[0]);
  let collectionName = collection.split(",")[1];
  let purityId = purity !== "" ? parseInt(purity.split(",")[0]) : 0;
  let purityName = purity.split(",")[1];
  let partyId = parseInt(partyTypeId.split(",")[0]);
  let partyName = partyTypeId.split(",")[1];

  const filteredProducts = productTypeData.filter(
    (product) => product.CategoryId == newSku.CategoryId
  );
  const filteredCollection = collectionTypeData.filter(
    (product) => product.ProductId == newSku.ProductId
  );
  const filteredPurity = purityData.filter(
    (product) => product.CategoryId == newSku.CategoryId
  );
  const filteredBoxes = boxData.filter(
    (product) => product.ProductId == newSku.ProductId
  );

  const [stones, setStones] = useState([
    { stoneName: "", stoneWeight: "", stoneAmount: "", stonePieces: "" },
  ]);

  //   const updateStone = (index, field, value) => {
  //     const updatedStones = [...stones];
  //     updatedStones[index][field] = value;
  //     setStones(updatedStones);

  //     // Update corresponding state based on the field and index
  //     switch (field) {
  //       case "stoneAmount":
  //         eval(`setStoneAmount${index + 1}`)(value);
  //         break;
  //       case "stoneName":
  //         eval(`setStoneName${index + 1}`)(value);
  //         break;
  //       case "stoneWeight":
  //         eval(`setStoneWt${index + 1}`)(value);
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  const updateStone = (index, field, value) => {
    console.log(index, "index");
    console.log(field, "field");
    console.log(value, "value");
    const updatedStones = [...newSku.Stones];
    const updatedStone = { ...updatedStones[index], [field]: String(value) };

    // Update stone amount based on the rate from allStones when the field is "StoneName"
    if (field === "StoneName") {
      const selectedStoneFromAllStones = allStones.find(
        (stone) => stone.StoneName === value
      );
      if (selectedStoneFromAllStones) {
        updatedStone.StoneRate = String(selectedStoneFromAllStones.StoneRate);
        updatedStone.Description = String(
          selectedStoneFromAllStones.Description
        );

        setSelectedStoneRate(String(selectedStoneFromAllStones.StoneAmount));
      }
    }

    // If the field is "StoneRate" or "StonePieces", update the total amount
    if (field === "StoneRate" || field === "StonePieces") {
      const stonePieces =
        field === "StonePieces" ? value : updatedStone.StonePieces || "0";
      const stoneAmount =
        field === "StoneRate" ? value : updatedStone.StoneRate || "0";
      const totalAmount = Number(stonePieces) * Number(stoneAmount);
      updatedStone.StoneAmount = String(totalAmount.toFixed(2));
    }

    if (field === "StoneWeight") {
      if (value !== "") {
        const updatedGrossWt =
          parseFloat(netWt) + parseFloat(stoneWeight) + parseFloat(value);
        const updatedStoneWeight = parseFloat(stoneWeight) + parseFloat(value);
        setGrosswt(String(updatedGrossWt.toFixed(2)));
        setStoneWeight(String(updatedStoneWeight.toFixed(2)));
      }
    }

    updatedStones[index] = updatedStone;
    setNewSku((previousState) => ({
      ...previousState,
      Stones: updatedStones.map((stone) => ({
        ...stone,
        StoneRate: String(stone.StoneRate),
        StoneAmount: String(stone.StoneAmount),
        StonePieces: String(stone.StonePieces),
        StoneWeight: stone.StoneWeight
          ? String(stone.StoneWeight)
          : stone.StoneWeight,
      })),
    }));
    console.log(updatedStone, "updatedStone");
    console.log(updatedStone, "updatedStone");
    console.log(updatedStone, "updatedStone");
  };

  const updateDiamond = (index, field, value) => {
    console.log(index, "index");
    console.log(field, "field");
    console.log(value, "value");
    const updatedDiamonds = [...newSku.Diamonds];
    const updatedDiamond = {
      ...updatedDiamonds[index],
      [field]: String(value),
    };

    // Update Diamond amount based on the rate from allDiamonds when the field is "DiamondName"
    if (field === "DiamondName") {
      const selectedDiamondFromAllDiamonds = allDiamonds.find(
        (diamond) => diamond.DiamondName === value
      );
      if (selectedDiamondFromAllDiamonds) {
        updatedDiamond.DiamondRate = String(
          selectedDiamondFromAllDiamonds.DiamondRate
        );
        updatedDiamond.Description = String(
          selectedDiamondFromAllDiamonds.Description
        );

        setSelectedDiamondRate(
          String(selectedDiamondFromAllDiamonds.DiamondAmount)
        );
      }
    }

    // If the field is "DiamondRate" or "DiamondPieces", update the total amount
    if (field === "DiamondRate" || field === "DiamondPieces") {
      const diamondPieces =
        field === "DiamondPieces" ? value : updatedDiamond.DiamondPieces || "0";
      const diamondAmount =
        field === "DiamondRate" ? value : updatedDiamond.DiamondRate || "0";
      const totalAmount = Number(diamondPieces) * Number(diamondAmount);
      updatedDiamond.DiamondAmount = String(totalAmount.toFixed(2));
    }

    if (field === "DiamondWeight") {
      if (value !== "") {
        const updatedGrossWt =
          parseFloat(netWt) + parseFloat(diamondWeight) + parseFloat(value);
        const updatedDiamondWeight =
          parseFloat(diamondWeight) + parseFloat(value);
        setGrosswt(String(updatedGrossWt.toFixed(2)));
        setDiamondWeight(String(updatedDiamondWeight.toFixed(2)));
      }
    }

    updatedDiamonds[index] = updatedDiamond;
    setNewSku((previousState) => ({
      ...previousState,
      Diamonds: updatedDiamonds.map((diamond) => ({
        ...diamond,
        DiamondRate: String(diamond.DiamondRate),
        DiamondAmount: String(diamond.DiamondAmount),
        DiamondPieces: String(diamond.DiamondPieces),
        DiamondWeight: diamond.DiamondWeight
          ? String(diamond.DiamondWeight)
          : diamond.DiamondWeight,
      })),
    }));
    console.log(updatedDiamond, "updatedDiamond");
    console.log(updatedDiamond, "updatedDiamond");
    console.log(updatedDiamond, "updatedDiamond");
    // Update corresponding state based on the field and index
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    const validFileCount = Math.min(files.length, 5 - selectedFiles.length);

    // Condition to reset the file selection
    if (!oldEntry || filesType) {
      // If we're not in a reset condition or if filesType is true, append new files respecting the limit
      const newFiles = Array.from(files).slice(0, validFileCount);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } else {
      // Reset condition met, prepare to replace selectedFiles entirely
      setFilesType(true); // Assuming this is needed to indicate the type/state of file selection
      // Since we're resetting, consider the total limit from scratch
      const newFiles = Array.from(files).slice(0, Math.min(files.length, 5));
      setSelectedFiles(newFiles);
    }
  };
  console.log(selectedFiles, "selectedFiles");
  console.log(selectedFiles, "selectedFiles");
  console.log(selectedFiles, "selectedFiles");
  const handleFileInputChangeRemove = (indexToRemove) => {
    const newSet = selectedFiles.filter(
      (file, index) => index !== indexToRemove
    );
    setSelectedFiles(newSet);
    if (selectedFiles.length == 1) {
      setSelectedFiles([]);
    }
  };

  //   console.log(stoneName1, "stoneName1");
  //   console.log(stoneAmount1, "stoneAmount1");
  //   console.log(stoneName2, "stoneName2");
  //   console.log(stoneAmount2, "stoneAmount2");
  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);

  useEffect(() => {
    let selectedPurityRate =
      newSku.PurityId !== 0
        ? purityData.filter(
            (x) => parseInt(x.Id) == parseInt(newSku.PurityId)
          )[0].TodaysRate
        : 0;
    console.log(selectedPurityRate, "selectedPurityRate");
    console.log(selectedPurityRate, "selectedPurityRate");
    console.log(selectedPurityRate, "selectedPurityRate");
    let TotalMetAmount = (parseFloat(newSku.NetWt) * selectedPurityRate) / 10;
    setTotalMetalAmount((parseFloat(newSku.NetWt) * selectedPurityRate) / 10);
    console.log(totalMetalAmount, "totalMetalAmount");

    let TotalStoneAmount = newSku.Stones.reduce(
      (a, b) => a + parseFloat(b.StoneAmount),
      0
    );
    setTotalStoneAmount(TotalStoneAmount);

    let makingCharge1 =
      (((selectedPurityRate * parseFloat(newSku.NetWt)) / 10) *
        parseFloat(newSku.MakingPercentage)) /
      100;
    let makingCharge2 =
      parseFloat(newSku.NetWt) * parseFloat(newSku.MakingPerGram);
    let makingCharge3 = parseFloat(newSku.MakingFixedAmt);
    let TotalMaking =
      parseFloat(makingCharge1) +
      parseFloat(makingCharge2) +
      parseFloat(makingCharge3);

    setLabourAmount(TotalMaking);
    calculateTotalProductPrice(
      TotalMetAmount,
      TotalStoneAmount,
      TotalMaking,
      mrp
    );
  }, [
    netWt,
    purity,
    grosswt,
    stoneWeight,
    stones,
    making_Percentage,
    making_per_gram,
    making_Fixed_Amt,
    mrp,
    newSku,
  ]);
  const calculateTotalProductPrice = (metal, stone, making, mrp) => {
    setTotalProductAmount(() => {
      // Ensure mrp is used directly if valid, otherwise calculate the sum, defaulting each part to 0 if not a valid number
      return newSku.MRP && !isNaN(newSku.MRP) && parseFloat(newSku.MRP) !== 0
        ? parseFloat(newSku.MRP)
        : (isNaN(metal) ? 0 : parseFloat(metal)) +
            (isNaN(stone) ? 0 : parseFloat(stone)) +
            (isNaN(making) ? 0 : parseFloat(making));
    });
  };

  const resetAllFields = () => {
    setShowError(false);
    setMessageType("");
    setMessageToShow("");
    setTotalMetalAmount("");
    setLabourAmount("");
    setTotalStoneAmount("");
    setTotalProductAmount("");

    setNewSku({
      BlackBeads: "",
      BoxId: "0",
      BoxName: "",
      BranchId: 0,
      BranchName: "Home",
      CategoryId: 1,
      ClientCode: clientCode,
      CollectionName: "",
      Colour: "",
      CompanyId: 0,
      CounterId: 0,
      CuttingGrossWt: "",
      CuttingNetWt: "",
      Description: "",
      DesignId: 0,
      Diamonds: [],
      EmployeeId: 0,
      EstimatedDays: "0",
      Featured: "Yes",
      Gender: "",
      GrossWt: "0",
      HSNCode: "",
      HUIDCode: "",
      HallmarkAmount: "0",
      Height: "0",
      Id: 0,
      Images: "",
      MRP: "0",
      MakingFixedAmt: "0",
      MakingFixedWastage: "0",
      MakingPerGram: "0",
      MakingPercentage: "0",
      Margin: "0",
      MetalName: "",
      MetalRate: "0",
      MinQuantity: "0",
      MinWeight: "0",
      NetWt: "0",
      OccassionName: "",
      OfferPrice: "0",
      Pieces: "1",
      ProductId: 0,
      ProductRemark: "",
      PurchaseCost: "",
      PurityId: 0,
      Quantity: "1",
      Ranking: "0",
      Size: "0",
      SketchNo: "",
      Status: "Active",
      StockKeepingUnit: "",
      Stones: [],
      TotalDiamondAmount: "0",
      TotalDiamondPieces: "0",
      TotalDiamondWeight: "0",
      TotalStoneAmount: "0",
      TotalStonePieces: "0",
      TotalStoneWeight: "0",
      VendorId: 0,
      WeightCategories: "",
      Width: "0",
      oldEntry: false,
    });

    // Resetting additional states involved in your form
    setPartyTypeId("");
    setCategory("");
    setProductType("");
    setCollection("");
    setPurity("");

    setStockKeepingUnit("");
    setProductRemark("");
    setGrosswt(0);
    setNetWt(0);
    setStoneWeight(0);
    setSelectedFiles([]);
    setSize("0");
    setDescription("");
    setMaking_per_gram(0);
    setMaking_Fixed_Amt(0);
    setMaking_Percentage(0);
    setMaking_Fixed_Wastage(0);
    setMRP(0);
    setColor("");

    setSaleType("MRP");
    setStonePieces(0);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleEditData = (data) => {
    console.log(data, "data");
    console.log(data, "data");
    console.log(data, "data");

    setNewSku({
      ...data,
      oldEntry: true,
      Stones: data.Stones,
      Diamonds: data.Diamonds,
      ClientCode: clientCode,
    });
    setActive("AddNew");
    setStockKeepingUnit(data.StockKeepingUnit);
    setDescription(data.Description);
    setProductRemark(data.ProductRemark);
    setCategory(`${data.CategoryId},${data.category}`);
    categoryName = data.category;
    categoryId = data.categoryId;
    setProductType(`${data.ProductId},${data.productType}`);
    productTypeName = data.productType;
    productTypeId = data.productTypeId;
    setCollection(`${data.DesignId},${data.collection}`);
    collectionName = data.collection;
    collectionId = data.collectionId;
    setPurity(`${data.PurityId},${data.purity}`);
    purityName = data.purity;
    purityId = data.purityId;
    setColor(data.Colour);
    setSize(data.Size);
    setGrosswt(data.GrossWt);
    setStoneWeight(data.TotalStoneWeight);
    setNetWt(data.NetWt);
    setMaking_Percentage(data.MakingPercentage);
    setMaking_per_gram(data.MakingPerGram);
    setMaking_Fixed_Amt(data.MakingFixedAmt);
    setMaking_Fixed_Wastage(data.MakingFixedWastage);
    setStonePieces(data.TotalStonePieces);
    setOldEntry(true);
    setFilesType(false);
    setSelectedFiles(data.Images);
    setSelectedSkuId(data.Id);
  };
  console.log(selectedFiles, "selectedFiles");
  console.log(selectedFiles, "selectedFiles");
  console.log(newSku, "newSku");
  console.log(newSku, "newSku");

  const deleteStone = (index) => {
    const updatedStones = newSku.Stones.filter((_, i) => i !== index);
    setNewSku({ ...newSku, Stones: updatedStones });
  };
  const deleteDiamond = (index) => {
    const updatedDiamonds = newSku.Diamonds.filter((_, i) => i !== index);
    setNewSku({ ...newSku, Diamonds: updatedDiamonds });
  };

  // You now have a File object

  //   Add bulk stock
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        {showError ? (
          <AlertMessage message={messageToShow} type={messageType} />
        ) : null}
        <AdminBreadCrump
          title={"Add SKU"}
          companyName={"Loyalstring"}
          module={"Masters"}
          page={"SKU"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 01 */}
                  <RiListUnordered />
                </div>
                <p>All SKU</p>
              </div>

              <div
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 02 */}
                  <RiPlayListAddLine />
                </div>
                <p>Add SKU</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>ID</th>
                    <th>SKU</th>
                    <th>Category</th>
                    <th>Product Type</th>
                    <th>Collection</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {allSku.map((x) => (
                    <tr key={x.id}>
                      <td>
                        {editingId === x.id ? (
                          <button
                            className="adminAddCategorySaveButton"
                            onClick={handleSaveClick}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="adminAddCategoryEditButton"
                            // onClick={() => handleEditClick(x.id)}
                            onClick={() => handleEditData(x)}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                      <td>{x.Id}</td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="stockKeepingUnit"
                            value={editedData.stockKeepingUnit || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.StockKeepingUnit
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="category"
                            value={editedData.category || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.category
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="productType"
                            value={editedData.productType} // Convert to uppercase
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.productType
                        )}
                      </td>
                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="collection"
                            value={editedData.collection} // Convert to uppercase
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.collection
                        )}
                      </td>

                      <td>
                        {editingId === x.id ? (
                          <input
                            type="text"
                            name="description"
                            value={editedData.description || ""}
                            onChange={handleInputChange}
                          />
                        ) : (
                          x.Description
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Sku</p>
              <form onSubmit={addNewSku}>
                <div className="adminCategoryAddCategoryInnerBox">
                  <div className="adminSkuAddSkuInnerBox">
                    <div
                      style={{ gridColumn: "1 span" }}
                      className="adminSkuAddSkuInnerUpperItemsBox"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label>
                          SKU <sup> *</sup>
                        </label>
                        <input
                          type="text"
                          value={newSku.StockKeepingUnit}
                          // onChange={(e) => {
                          //   setStockKeepingUnit(e.target.value.toUpperCase());
                          // }}
                          name="StockKeepingUnit"
                          onChange={handleNewSkuChange}
                        />
                        <label style={{ margin: 0, cursor: "pointer" }}>
                          Images{" "}
                          {`${
                            filesType
                              ? selectedFiles.length
                              : selectedFiles.split(",").length
                          }`}
                          <FaImages
                            style={{ margin: "1.2rem", marginInline: "1rem" }}
                            size={"2.5rem"}
                          />
                          <input
                            id="images"
                            style={{ display: "none" }}
                            type="file"
                            onChange={handleFileInputChange}
                            multiple
                          />
                        </label>
                      </div>
                    </div>
                    {/* <div
                      style={{ gridColumn: "span 0.2" }}
                      className="adminSkuAddSkuInnerUpperItemsBox"
                    >
                    
                      <label style={{ margin: 0, cursor: "pointer" }}>
                        Images {`${selectedFiles.length}`}
                        <FaImages
                          style={{ margin: "1.2rem", marginInline: "1rem" }}
                          size={"2.5rem"}
                        />
                        <input
                          id="images"
                          style={{ display: "none" }}
                          type="file"
                          onChange={handleFileInputChange}
                          multiple
                        />
                      </label>
                    
                    </div> */}
                    <div
                      style={{ gridColumn: "span 2", overflowX: "auto" }}
                      className="adminSkuAddSkuInnerUpperItemsBox"
                    >
                      <div className="adminSkuAddSkuInnerItemsImagesBox">
                        {filesType && selectedFiles.length > 0
                          ? selectedFiles.map((file, index) => (
                              <div className="adminSkuAddSkuInnerItemsImagesInnerBox">
                                <img
                                  key={index}
                                  src={URL.createObjectURL(file)}
                                  alt={`Selected Image ${index + 1}`}
                                  style={{
                                    maxWidth: "50px",
                                    maxHeight: "50px",
                                    margin: "5px",
                                  }}
                                />
                                <div
                                  onClick={() =>
                                    handleFileInputChangeRemove(index)
                                  }
                                  className="adminSkuAddSkuInnerItemsImagesBoxCancel"
                                >
                                  <RxCross2 strokeWidth={"2px"} />
                                </div>
                              </div>
                            ))
                          : !filesType && oldEntry && selectedFiles !== ""
                          ? selectedFiles.split(",").map((image, index) => (
                              <div className="adminSkuAddSkuInnerItemsImagesInnerBox">
                                <img
                                  key={index}
                                  style={{
                                    maxWidth: "50px",
                                    maxHeight: "50px",
                                    margin: "5px",
                                  }}
                                  className="adminOrderDetailsItemsproductImage"
                                  src={`${s1}/${image.trim()}`}
                                  alt={`Product Image ${index + 1}`}
                                />
                                {/* <div
                                  onClick={() =>
                                    handleFileInputChangeRemoveExisting(index)
                                  }
                                  className="adminSkuAddSkuInnerItemsImagesBoxCancel"
                                >
                                  <RxCross2 strokeWidth={"2px"} />
                                </div> */}
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    <div
                      style={{ gridColumn: "span 2" }}
                      className="adminSkuAddSkuInnerUpperItemsBox"
                    >
                      <label>Description</label>
                      <textarea
                        type="text"
                        name="Description"
                        value={newSku.Description}
                        onChange={handleNewSkuChange}
                      />
                    </div>
                    <div
                      style={{ gridColumn: "span 1" }}
                      className="adminSkuAddSkuInnerUpperItemsBox"
                    >
                      <label>Product Remark</label>
                      <textarea
                        type="text"
                        name="ProductRemark"
                        value={newSku.ProductRemark}
                        onChange={handleNewSkuChange}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>
                        Category <sup> *</sup>
                      </label>
                      <select
                        name="CategoryId"
                        value={newSku.CategoryId}
                        onChange={handleNewSkuChange}
                      >
                        <option value="">Category</option>
                        {categoriesData.map((x, y) => {
                          return (
                            <option key={y} value={x.Id}>
                              {x.CategoryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>
                        Product <sup> *</sup>
                      </label>
                      <select
                        name="ProductId"
                        value={newSku.ProductId}
                        onChange={handleNewSkuChange}
                      >
                        <option value="">Product Type</option>
                        {filteredProducts.map((x, y) => {
                          return (
                            <option key={y} value={parseInt(x.Id)}>
                              {x.ProductName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>
                        Design <sup> *</sup>
                      </label>
                      <select
                        name="DesignId"
                        value={newSku.DesignId}
                        onChange={handleNewSkuChange}
                      >
                        <option value="">Design</option>
                        {filteredCollection.map((x, y) => {
                          return (
                            <option key={y} value={parseInt(x.Id)}>
                              {x.DesignName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>
                        Purity <sup> *</sup>
                      </label>
                      <select
                        name="PurityId"
                        value={newSku.PurityId}
                        onChange={handleNewSkuChange}
                      >
                        <option value="">Purity</option>
                        {filteredPurity.map((x, y) => {
                          return (
                            <option key={y} value={parseInt(x.Id)}>
                              {x.PurityName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Colour</label>
                      <input
                        type="text"
                        name="Colour"
                        value={newSku.Colour}
                        onChange={handleNewSkuChange}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Size</label>
                      <input
                        type="text"
                        name="Size"
                        value={newSku.Size}
                        onChange={handleNewSkuChange}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label htmlFor="grosswt">
                        G.Wt <sup> *</sup>
                      </label>
                      <input
                        min="0"
                        type="number"
                        name="GrossWt"
                        value={newSku.GrossWt}
                        onChange={handleNewSkuChange}
                        // onChange={(e) => {
                        //   if (
                        //     e.target.value >= 0 &&
                        //     e.target.value > grosswt - stoneWeight
                        //   ) {
                        //     setGrosswt(e.target.value),
                        //       setNetWt(
                        //         parseFloat(e.target.value) -
                        //           parseFloat(stoneWeight)
                        //       );
                        //   } else if (e.target.value >= 0) {
                        //     setGrosswt(e.target.value);
                        //     setStoneWeight(0);
                        //     setNetWt(e.target.value);
                        //   }
                        // }}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label htmlFor="stoneWeight">
                        Total St.Wt <sup> *</sup>
                      </label>

                      <input
                        min="0"
                        type="number"
                        name="TotalStoneWeight"
                        value={newSku.TotalStoneWeight}
                        onChange={handleNewSkuChange}
                      />
                    </div>
                    <div className="adminSkuAddSkuInnerItemsBox">
                      <label htmlFor="netWt">Net.Wt</label>

                      <input
                        type="number"
                        value={newSku.NetWt}
                        readOnly
                        // onChange={(e) => {
                        //   setNetWt(e.target.value),
                        //     setGrosswt(
                        //       parseFloat(e.target.value) +
                        //         parseFloat(stoneWeight)
                        //     );
                        // }}
                      />
                    </div>
                    <p className="adminSkuAddSkuInnerTitles">ADD LABOUR</p>
                    {/* <div className="adminSkuAddSkuInnerUpperItemsBox">s */}
                    {/* <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Sale Type</label>
                      <select
                        value={saleType}
                        onChange={(e) => setSaleType(e.target.value)}
                      >
                        <option value={"MRP"}>MRP Based</option>
                        <option value={"Weight"}>Weight Based</option>
                      </select>
                    </div> */}
                    {/* {saleType === "MRP" ? ( */}
                    {/* <div className="adminSkuAddSkuInnerItemsBox">
                      <label>MRP</label>
                      <input
                        type="number"
                        value={mrp}
                        onChange={(e) => {
                          setMRP(e.target.value);
                        }}
                      />
                    </div> */}

                    <>
                      <div className="adminSkuAddSkuInnerItemsBox">
                        <label>Making Percentage</label>
                        <input
                          type="number"
                          min="0"
                          name="MakingPercentage"
                          value={newSku.MakingPercentage}
                          onChange={handleNewSkuChange}
                        />
                      </div>
                      <div className="adminSkuAddSkuInnerItemsBox">
                        <label>Making Per/Gram</label>
                        <input
                          min="0"
                          type="number"
                          name="MakingPerGram"
                          value={newSku.MakingPerGram}
                          onChange={handleNewSkuChange}
                        />
                      </div>
                      <div className="adminSkuAddSkuInnerItemsBox">
                        <label>Making Fixed Amount</label>
                        <input
                          min="0"
                          type="number"
                          name="MakingFixedAmt"
                          value={newSku.MakingFixedAmt}
                          onChange={handleNewSkuChange}
                        />
                      </div>
                      {/* <div className="adminSkuAddSkuInnerItemsBox">
                        <label>Making Fixed Wastage</label>
                        <input
                          type="number"
                          value={making_Fixed_Wastage}
                          onChange={(e) => {
                            setMaking_Fixed_Wastage(e.target.value);
                          }}
                        />
                      </div> */}
                    </>

                    {/* <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Stone Pieces</label>
                      <input
                        type="number"
                        value={stonePieces}
                        onChange={(e) => {
                          setStonePieces(e.target.value);
                        }}
                      />
                    </div> */}
                  </div>
                  <p className="adminSkuAddSkuInnerTitles">ADD STONE</p>
                  {/* <div className="adminSkuAddSkuInnerItemsBox">
                      <label>Stone Name 1</label>
                      <input
                        type="text"
                        value={stoneName1}
                        onChange={(e) => {
                          handleStoneName(e.target.value, 1);
                        }}
                        list="stonesList"
                      />
                      <datalist id="stonesList">
                        {allStones.map((stone, index) => (
                          <option key={index} value={`${stone.stoneName}`} />
                        ))}
                      </datalist>
                    </div> */}
                  <div className="adminSkuAddSkuInnerItemsStoneBox">
                    {newSku.Stones.map((stone, index) => (
                      <div className="adminSkuAddSkuInnerItemsStoneInnerBox">
                        <div
                          key={index}
                          className="adminSkuAddSkuInnerItemsBox"
                        >
                          <label>Stone Name</label>
                          <input
                            type="text"
                            value={stone.StoneName}
                            placeholder={`Stone Name ${index + 1}`}
                            onChange={(e) =>
                              updateStone(index, "StoneName", e.target.value)
                            }
                            list="stonesList"
                          />
                          <datalist id="stonesList">
                            {allStones.map((stone, index) => (
                              <option
                                key={index}
                                value={`${stone.StoneName}`}
                              />
                            ))}
                          </datalist>
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Stone Weight</label>
                          <input
                            type="text"
                            value={stone.StoneWeight}
                            placeholder={`Stone Weight ${index + 1}`}
                            onChange={(e) =>
                              updateStone(index, "StoneWeight", e.target.value)
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Stone Pieces</label>
                          <input
                            type="text"
                            value={stone.StonePieces}
                            placeholder={`Stone Pieces ${index + 1}`}
                            onChange={(e) =>
                              updateStone(index, "StonePieces", e.target.value)
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Stone Rate</label>
                          <input
                            type="text"
                            value={stone.StoneRate}
                            placeholder={`Stone Rate ${index + 1}`}
                            onChange={(e) =>
                              updateStone(index, "StoneRate", e.target.value)
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Stone Amount</label>
                          <input
                            type="text"
                            value={stone.StoneAmount}
                            placeholder={`Stone Amount ${index + 1}`}
                            readOnly
                            // onChange={(e) =>
                            //   updateStone(index, "StoneAmount", e.target.value)
                            // }
                          />
                        </div>
                        {/* {stones.length > 1 && ( */}
                        <div
                          style={{ alignSelf: "flex-end" }}
                          className="adminSkuAddSkuInnerItemsBox"
                        >
                          <button
                            style={{ margin: "0px" }}
                            type="button"
                            onClick={() => deleteStone(index)}
                          >
                            Remove
                          </button>
                        </div>
                        {/* )} */}
                      </div>
                    ))}
                    {stones.length < 4 ? (
                      <button
                        type="button"
                        className="adminSkuAddSkuInnerAddStoneButton"
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          width: "200px",
                        }}
                        onClick={() =>
                          setNewSku((previousState) => ({
                            ...previousState,
                            Stones: [...previousState.Stones, addStone],
                          }))
                        }
                      >
                        Add Stone
                      </button>
                    ) : null}
                  </div>
                  <p className="adminSkuAddSkuInnerTitles">ADD DIAMONDS</p>
                  <div className="adminSkuAddSkuInnerItemsStoneBox">
                    {newSku.Diamonds.map((diamond, index) => (
                      <div className="adminSkuAddSkuInnerItemsStoneInnerBox">
                        <div
                          key={index}
                          className="adminSkuAddSkuInnerItemsBox"
                        >
                          <label>Diamond Name</label>
                          <input
                            type="text"
                            value={diamond.DiamondName}
                            placeholder={`Diamond Name ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondName",
                                e.target.value
                              )
                            }
                            list="diamondsList"
                          />
                          <datalist id="diamondsList">
                            {allDiamonds.map((diamond, index) => (
                              <option
                                key={index}
                                value={`${diamond.DiamondName}`}
                              />
                            ))}
                          </datalist>
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Weight</label>
                          <input
                            type="text"
                            value={diamond.DiamondWeight}
                            placeholder={`Diamond Weight ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondWeight",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Pieces</label>
                          <input
                            type="text"
                            value={diamond.diamondPieces}
                            placeholder={`Diamond Pieces ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondPieces",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Rate</label>
                          <input
                            type="text"
                            value={diamond.DiamondRate}
                            placeholder={`Diamond Rate ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondRate",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Amount</label>
                          <input
                            type="text"
                            value={diamond.DiamondAmount}
                            placeholder={`Diamond Amount ${index + 1}`}
                            readOnly
                            // onChange={(e) =>
                            //   updateDiamond(index, "StoneAmount", e.target.value)
                            // }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Clarity</label>
                          <input
                            type="text"
                            value={diamond.DiamondClarity}
                            placeholder={`Diamond Clarity ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondClarity",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Colour</label>
                          <input
                            type="text"
                            value={diamond.DiamondColour}
                            placeholder={`Diamond Colour ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondColour",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Cut</label>
                          <input
                            type="text"
                            value={diamond.DiamondCut}
                            placeholder={`Diamond Cut ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(index, "DiamondCut", e.target.value)
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Shape</label>
                          <input
                            type="text"
                            value={diamond.DiamondShape}
                            placeholder={`Diamond Shape ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondShape",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Diamond Size</label>
                          <input
                            type="text"
                            value={diamond.DiamondSize}
                            placeholder={`Diamond Size ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "DiamondSize",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Certificate</label>
                          <input
                            type="text"
                            value={diamond.Certificate}
                            placeholder={`Diamond Size ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "Certificate",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Setting Type</label>
                          <input
                            type="text"
                            value={diamond.SettingType}
                            placeholder={`Setting Type ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "SettingType",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="adminSkuAddSkuInnerItemsBox">
                          <label>Description</label>
                          <input
                            type="text"
                            value={diamond.Description}
                            placeholder={`Description ${index + 1}`}
                            onChange={(e) =>
                              updateDiamond(
                                index,
                                "Description",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        {/* {stones.length > 1 && ( */}
                        <div
                          style={{ alignSelf: "flex-end" }}
                          className="adminSkuAddSkuInnerItemsBox"
                        >
                          <button
                            style={{ margin: "0px" }}
                            type="button"
                            onClick={() => deleteDiamond(index)}
                          >
                            Remove
                          </button>
                        </div>
                        {/* )} */}
                      </div>
                    ))}
                    {newSku.Diamonds.length < 4 ? (
                      <button
                        type="button"
                        className="adminSkuAddSkuInnerAddStoneButton"
                        style={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          width: "200px",
                        }}
                        onClick={() =>
                          setNewSku((previousState) => ({
                            ...previousState,
                            Diamonds: [...previousState.Diamonds, addDiamond],
                          }))
                        }
                      >
                        Add Diamond
                      </button>
                    ) : null}
                  </div>
                  <div
                    style={{ gridColumn: "span 3" }}
                    className="adminSkuAddSkuInnerItemsBox"
                  >
                    <table>
                      <thead>
                        <tr>
                          <td>METAL AMOUNT</td>
                          <td>STONE AMOUNT</td>
                          <td>LABOUR AMOUNT</td>
                          <td>TOTAL AMOUNT</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {totalMetalAmount
                              ? parseFloat(totalMetalAmount).toFixed(0)
                              : 0}
                          </td>
                          <td>
                            {totalStoneAmount
                              ? parseFloat(totalStoneAmount).toFixed(0)
                              : 0}
                          </td>
                          <td>
                            {totalLabourAmount
                              ? parseFloat(totalLabourAmount).toFixed(0)
                              : 0}
                          </td>
                          <td>{totalProductAmount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{ gridColumn: "span 3" }}
                    // style={{ display: "block" }}
                    // className="adminSkuAddSkuInnerItemsBox"
                  >
                    <label style={{ marginBottom: "5px" }}>MRP</label>
                    <input
                      min="0"
                      type="number"
                      name="MRP"
                      value={newSku.MRP}
                      onChange={handleNewSkuChange}
                    />
                  </div>
                  <div
                    style={{ gridColumn: "span 3" }}
                    className="adminSkuAddSkuInnerItemsBox"
                  >
                    <button
                      style={{ width: "200px", marginRight: "20px" }}
                      type="submit"
                    >
                      SUBMIT
                    </button>
                    {/* </div> */}
                    {/* <div
                    style={{ gridColumn: "span 1" }}
                    className="adminSkuAddSkuInnerItemsBox"
                  > */}
                    {/* <div
                    style={{ gridColumn: "span 1" }}
                    className="adminSkuAddSkuInnerItemsBox"
                  > */}
                    <button
                      onClick={resetAllFields}
                      style={{ width: "200px" }}
                      type="button"
                    >
                      Reset
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
